import { useCallback, useEffect } from 'react';
import { clarityConfig } from 'config';

export const useClarity = (): void => {
  const { clarityId } = clarityConfig;

  const scriptParaIncluirClarity = useCallback(() => {
    if (document.getElementById('script-clarity') || !clarityId) {
      return;
    }

    const script = document.createElement('script');
    script.id = 'script-clarity';
    script.type = 'text/javascript';
    script.innerHTML = `
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
        t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "${clarityId}");`;

    document.head.appendChild(script);
  }, [clarityId]);

  useEffect(() => {
    scriptParaIncluirClarity();
  }, [scriptParaIncluirClarity]);
};
